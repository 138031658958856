/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import MetaDownload from "../Elements/MetaDownload"
import CircleAvatar from "../Elements/CircleAvatar"
import AddToWishlist from "../Elements/AddToWishlist"
import CartContext from "../../context/Cart/CartContext"
import Button from "../Elements/Button"
import Tag from "../Elements/Tag"
import { Link, navigate } from "gatsby"
import MetaDelivery from "../Elements/MetaDelivery"
import MetaMini from "../Elements/MetaMini"
import NotificationContext from "../../context/Notification/NotificationContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import {
  addmyBookmark,
  getSingleAssetQty,
  getAssetSubcriptionInventory,
  subcribeAssetInventory,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import DataContext from "../../context/Data/DataContext"
import Skeleton from "../Skeleton"
import Separator from "../Elements/Separator"
import LazyImage from "../LazyImage"
import ProductImageBox from "../ProductImageBox"
import Tags from "../Elements/Tags"
import PlaceholderImage from "../../assets/images/image_coming_soon.png"
import { isLatestUpdated } from "../../helpers/GeneralHelper"
import Modal from "../Modal/Modal"
const formatDate = d =>
  typeof d === "string"
    ? d.split("/")[1] + "/" + d.split("/")[0] + "/" + d.split("/")[2]
    : String(d.getDate()).padStart(2, "0") +
    "/" +
    String(d.getMonth() + 1).padStart(2, "0") +
    "/" +
    d.getFullYear()
const diffDays = (date, otherDate) =>
  Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24))
const addMonths = (numOfMonths, date = new Date()) =>
  date.setMonth(date.getMonth() + numOfMonths)
const Meta = props => {
  return (
    <div className="flex items-center text-left grid grid-cols-2">
      <h2 className="text-med-14">{props.title}</h2>
      <h2 className="text-reg-14 text-dark whitespace-nowrap overflow-hidden text-ellipsis">
        {props.children}
      </h2>
    </div>
  )
}
function ProductGrid(props) {
  const assetData = props
  // console.log(assetData)
  const { searchResult, displayBlock, loading, hiddenCta, fromAssetListing } =
    props
  // let { id, image, name } = product
  // console.log(fromAssetListing)
  const {
    showCartModal,
    userAddresses,
    setOpenCartDropdown,
    orderDigitalAsset,
  } = useContext(CartContext)
  const { categoriesAsOptions, myBookmarksID } = useContext(DataContext).data
  const { currentUserData } = useContext(AuthenticationContext).authentication
  let coverImg = ""
  if (props.assets) {
    coverImg =
      props?.cover && Array.isArray(props?.cover) && props.cover[0]
        ? props.cover[0]
        : props.cover
  } else if (props.cover) {
    coverImg =
      props?.cover && Array.isArray(props?.cover) && props.cover[0]
        ? props.cover[0]
        : ""
  } else if (props.img) {
    coverImg =
      props?.img && Array.isArray(props?.img) && props.img[0]
        ? props.img[0]
        : ""
  }
  const [following, setFollowing] = useState(false)
  const [inventorySubscribed, setInventorySubscribed] = useState(null)
  const [data, setData] = useState(null)
  const [emptyAddressBookError, setEmptyAddressBookError] = useState(false)
  const [count, setCount] = useState(0)

  // const increaseQty = e => {
  //   const val = parseInt(e)

  //   if (val <= data.max_qty && val <= data.available_total) return setCount(val)
  //   else if (data.max_qty == null && val <= data.available_total)
  //     return setCount(val)

  //   return toggleNotification({
  //     error: true,
  //     content: `Sorry, we don't have enough in stock.`,
  //   })
  // }

  // const increment = () => {
  //   const val = count + 1

  //   increaseQty(val)

  //   // setCount(count + 1)
  //   // if (data.max_qty == null && count + 1 < data.available_total)
  //   //   setCount(count + 1)
  //   // else setCount(count + 1)
  // }
  // const decrement = () => (count > 0 ? setCount(count - 1) : null)

  const callInventorySuscribe = async () => {
    let iData = await getAssetSubcriptionInventory(token, props.id)

    if (iData === "Not subscribed") {
      // setInventorySubscribed(false)

      let body = {
        user: currentUserData.i,
        email: currentUserData.email,
        asset: props.id,
      }
      let res = await subcribeAssetInventory(token, body)
      if (res.status === 200) {
        toggleNotification({
          content: `${props.title} Inventory Successfully Subscribed`,
        })
        setInventorySubscribed(true)
      } else {
        toggleNotification({
          content: `Fail to subscribe ${props.title} inventory `,
          error: true,
        })
      }
    } else if (iData === "Already subscribed") {
      toggleNotification({
        content: `${props.title} Inventory Is Already Subscribed`,
      })
    } else setInventorySubscribed(true)
  }

  const { toggleNotification } = useContext(NotificationContext)
  const { token } = useContext(StateContext).state
  const onFollowClick = async () => {
    let body = {
      product: props.id,
    }
    if (props.assets) body = { asset: props.id }

    let res = await addmyBookmark(token, body)
    if (res.status === 200) {
      setFollowing(true)
      toggleNotification({ content: `You just bookmarked ${props.title}` })
    } else {
      toggleNotification({ content: "failed to bookmarked" })
    }
  }
  let linkUrl = ""

  if (props.fromAssetListing
  ) {
    if (props.bundle === "default") {
      linkUrl = `/asset/${props.id}`
    } else {
      linkUrl = `/assets/${props.id}`
    }

  } else if (props.assets) {
    if (props.assetsGroup) {
      if (props.bundle === "default") linkUrl = `/asset/${props.id}`
      else linkUrl = `/assets/${props.id}`
    } else {
      linkUrl = `/asset/${props.id}`
    }
  } else {
    linkUrl = `/product/${props.id}`
  }
  const [qtyData, setQtyData] = useState(null)

  useEffect(async () => {
    // alert(searchResult)
    if (searchResult === true) {
      let data = await getSingleAssetQty(token, props.id)
      setQtyData(data)
    }
  }, [props.id])

  const onAddToCartClick = async () => {
    if (assetData.dataType === "asset" || (assetData.type === "physical" && (assetData.status === "in-stock" || assetData.status === "low-stock"))) {
      // console.log(token)
      const data = await getSingleAssetQty(token, assetData.id)
      setData(data)
    }
  }

  useEffect(() => {

    if (data !== null) addToCart()

  }, [data])

  const addToCart = () => {
    // console.log({ data })
    if (userAddresses.length === 0) {
      setEmptyAddressBookError(true)
      // You must first create an address in your Address Book
      return
    }
    const nonHiddenAddresses = userAddresses.filter(
      item => item.hidden_from_cart !== true
    )
    const { status, user_group } = data
    if (!nonHiddenAddresses.length) {
      toggleNotification({
        error: true,
        content: `No valid delivery address was found. Please check your addresses to ensure your address is not hidden or deleted.`,
      })
    } else if (status === "can_order") {
      if (userAddresses.length === 1) {
        setOpenCartDropdown(props.id, count === 0 ? 1 : count, props.title, user_group)
      } else {
        showCartModal(props.id, {
          ...data,
          count: count,
          status,
          user_group,
          assetData: assetData,
        })
      }
    } else {
      showCartModal(props.id, {
        ...data,
        count: count,
        status,
        user_group,
        assetData: assetData,
      })
    }
  }
  const navigateCreateAddressBookItem = () => {
    navigate("/dashboard/address-book", {
      state: { create: true },
    })
  }

  const [stockStatus, setStockStatus] = useState(null)

  useEffect(() => {
    // {
    //   typeof props.status === "string" && (
    //     <>
    //       {
    //         {
    //           'in-stock': <Tag plain>In Stock</Tag>,
    //           'out-of-stock': <Tag red plain>Out of Stock</Tag>,
    //           'low-stock': <Tag yellow plain>Low Stock</Tag>,
    //         }[props.status]
    //       }
    //     </>
    //   )
    // }
    // {
    //   typeof props.status === "object" && (
    //     <>
    //     </>
    //   )
    // }
    // {
    //   Array.isArray(props.status) && (
    //     <>
    //       {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))) ? <>
    //         {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc < 1 && <Tag red plain>Out of Stock</Tag>}
    //         {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc > 0 && props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc < 5 && <Tag yellow plain>Low Stock</Tag>}
    //         {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc > 4 && <Tag plain>In Stock</Tag>}
    //       </> : <Tag red plain>Out of Stock</Tag>}
    //     </>
    //   )
    // }

    if (typeof props.status === "string") {
      setStockStatus(props.status)
    } else if (Array.isArray(props.status)) {
      let status = props.status.find(f =>
        currentUserData.ug.includes(parseInt(f.user_group))
      )
      if (status) {
        if (status.aloc < 1) setStockStatus("out-of-stock")
        else if (status.aloc > 0 && status.aloc < 5) setStockStatus("low-stock")
        else setStockStatus("in-stock")
      } else {
        setStockStatus("out-of-stock")
      }
    } else if (typeof props.status === "object") {
      if (props.status?.available_total < 1) setStockStatus("out-of-stock")
      else if (
        props.status?.available_total > 0 &&
        props.status?.available_total < 5
      )
        setStockStatus("low-stock")
      else setStockStatus("in-stock")
    }
  }, [props.id])

  const orderDigitalAssetFunction = async assetId => {
    // console.log(assetId, "assetId")
    const nonHiddenAddresses = userAddresses.filter(
      item => item.hidden_from_cart !== true
    )
    if (userAddresses.length === 0) {
      setEmptyAddressBookError(true)
      // You must first create an address in your Address Book
      return
    }

    if (!nonHiddenAddresses.length) {
      toggleNotification({
        error: true,
        content: `No valid delivery address was found. Please check your addresses to ensure your address is not hidden or deleted.`,
      })
    } else if (userAddresses.length === 1) {
      // console.log([userAddresses[0].id], "test")

      await orderDigitalAsset({
        assetId: assetId,
        addresses: [{ address_id: userAddresses[0].id }],
        title: assetData.title,
      })
      // do download
      // await onDownload(id)
    } else {
      showCartModal(assetData.id, {
        ...data,
        assetData: assetData,
      })
    }
  }
  // console.log(searchResult)
  if (searchResult) {
    return (
      <div className="group block bg-white rounded-[8px] mb-4 container transition-all">
        <Link to={linkUrl}>
          <h3 className="mb-4 text-med-20 group-hover:underline">{props.t}</h3>

          <div className="flex items-center justify-between mb-4">
            <CircleAvatar
              border
              image={
                props.assetsGroup
                  ? props.brand?.logo
                    ? props.brand?.logo
                    : props.brand_logo
                  : props.brand_logo
              }
              sm
              text={props.brand?.name || props.brand_name}
            />
            <div className="flex items-center justify-between ">
              {props.type === "physical" && (
                <div className="mr-4">
                  {props.assets && (
                    <Tags>
                      {qtyData === null && (
                        <Tag>
                          <Skeleton />
                        </Tag>
                      )}
                      {qtyData?.in_stock === true && <Tag>In Stock</Tag>}
                      {qtyData?.in_stock === false && (
                        <Tag red>Out of Stock</Tag>
                      )}
                    </Tags>
                  )}
                </div>
              )}
              <div className="mr-4">
                {props.type && props.type === "digital" && <MetaDownload />}
                {props.type && props.type === "physical" && <MetaDelivery />}
              </div>
            </div>
          </div>
        </Link>

        <Separator className="mt-6 mb-6" />
        <div className="flex">
          <div className="w-1/2">
            <ProductImageBox
              isAsset={false}
              images={props.cover}
              sm
              name={props.title}
              linkTo={linkUrl}
            />
          </div>
          <div className="w-1/2 pl-[30px]">
            <AddToWishlist
              title={props.title}
              id={props.id}
              name={props.assets ? "asset" : "product"}
              isListed={
                myBookmarksID[props.assets ? "asset" : "product"]?.includes(
                  props.id
                )
                  ? true
                  : false
              }
            />
            {props.assets && (
              <div className="mt-7">
                {qtyData === null ? (
                  <Skeleton />
                ) : qtyData.ordered_last ? (
                  <Meta title="Last Ordered">{qtyData.ordered_last}</Meta>
                ) : (
                  ""
                )}

                <Meta title="SKU">{props.sku}</Meta>
              </div>
            )}
            <Tag bold upper darkGreen>
              {props.assets &&
                props.category &&
                props.category.map((v, k) => (k === 0 ? v : ", " + v))}
              {!props.assets &&
                props.product_types?.length > 0 &&
                props.product_types.map((v, k) => {
                  let d = categoriesAsOptions.filter(c => c.value === v)[0]
                  if (d) return k === 0 ? d.label : ", " + d.label
                  else return k === 0 ? v : ", " + v
                })}
            </Tag>

            <div className="py-[30px]">
              <>
                {props.assetsItems && (
                  <MetaMini
                    grey
                    title={`Includes ${props.assetsItems.length} assets`}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (loading) {
    return (
      <div
        className={`group bg-white text-sm overflow-hidden rounded-[8px] border border-[#ebebeb] mb-4 container hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)] transition-all ${typeof displayBlock !== "undefined" && "block"
          }`}
      >
        <div className="relative">
          <div
            className={`absolute left-4 top-4 opacity-100 group-hover:opacity-0 transition-all z-[1]`}
          >
            <Tag bold upper darkGreen>
              <Skeleton />
            </Tag>
          </div>
          <div className="absolute right-4 top-4 opacity-0 group-hover:opacity-100 transition-all z-[1]">
            <Skeleton />
          </div>
          <div>
            <div className="w-full h-56 ">
              <Skeleton dark />

              {props.assets && !props.assetsGroup && (
                <div className="opacity-0 group-hover:opacity-100 transition-all	-translate-y-[70px] group-hover:-translate-y-[60px] px-[30px]">
                  <Button full red onClick={() => showCartModal(props.id)}>
                    <Skeleton />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="p-[30px]">
            <div className="flex items-center justify-between mb-4">
              <CircleAvatar border image={""} sm />
              {props.assets && !props.assetsGroup && <Skeleton />}
            </div>
            <h3 className="mb-2 text-med-16 group-hover:underline">
              <Skeleton />
            </h3>
            {props.assetsItems && (
              <p>
                <Skeleton />
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }
  // console.log(assetData)

  // console.log(props, 'props')
  if (fromAssetListing) {
    return (
      <>
        <div
          className={`group bg-white text-sm overflow-hidden rounded-[8px] border border-[#ebebeb] mb-4 container hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)] transition-all ${typeof displayBlock !== "undefined" && "block"
            }`}
        >
          <div className="relative">
            <div
              className={`flex items-center justify-center p-3 absolute gap-4 opacity-100 group-hover:opacity-0 transition-all z-[1]`}
            >
              {props.category.length > 0 && (
                <Tag bold upper darkGreen>
                  {
                    props.category &&
                    props.category.map((v, k) => (k === 0 ? v : ", " + v))}
                  {
                    props.categories?.length > 0 &&
                    props.categories.map((v, k) => (k === 0 ? v : ", " + v))}
                </Tag>
              )}
              <Tag bold upper>
                {typeof props.price !== "undefined" &&
                  props.price !== null &&
                  props.price !== "0.00" &&
                  `$${props.price}`}
                {typeof props.price !== "undefined" &&
                  props.price !== null &&
                  props.price === "0.00" &&
                  `FREE!`}
              </Tag>
            </div>
            {props.created_at && isLatestUpdated(props.created_at, 7) && (
              <div
                className={`absolute right-4 top-4 opacity-100 group-hover:opacity-0 transition-all z-[1]`}
              >
                <Tag bold upper red>
                  New
                </Tag>
              </div>
            )}
            <div className="absolute right-4 top-4 opacity-0 group-hover:opacity-100 transition-all z-[1]">
              <AddToWishlist
                noText
                title={props.title}
                id={props.id}
                name={props.assets ? "asset" : "product"}
                isListed={
                  myBookmarksID[props.assets ? "asset" : "product"]?.includes(
                    props.id
                  )
                    ? true
                    : false
                }
              />
            </div>
            <Link to={linkUrl}>
              <div className="w-full h-56 ">
                {typeof coverImg === "string" &&
                  (coverImg?.toLowerCase().includes(`.png`) ||
                    coverImg?.toLowerCase().includes(`.jpg`) ||
                    coverImg?.toLowerCase().includes(`.webp`) ||
                    coverImg?.toLowerCase().includes(`.jpeg`)) ? (
                  <LazyImage
                    src={coverImg}
                    alt={props.title}
                    className="w-full h-full object-center object-cover bg-[#EBEBEB]"
                  />
                ) : (
                  <LazyImage
                    src={PlaceholderImage}
                    alt={props.title}
                    className="w-full h-full object-center object-contain bg-[#fff]"
                  />
                )}
              </div>
            </Link>

            {
              !hiddenCta &&
              (props.assets.length === 0 || props.assets === false) &&
              // !props.assetsGroup &&
              props.type === "physical" &&
              stockStatus !== "out-of-stock" && (
                <div className="opacity-0 group-hover:opacity-100 transition-all	-translate-y-[70px] group-hover:-translate-y-[60px] px-[30px]">
                  <Button full red onClick={onAddToCartClick}>
                    Add to Cart
                  </Button>
                </div>
              )}
            {!hiddenCta &&
              props.assets &&
              !props.assetsGroup &&
              props.type === "physical" &&
              stockStatus === "out-of-stock" && (
                <div className="opacity-0 group-hover:opacity-100 transition-all	-translate-y-[70px] group-hover:-translate-y-[60px] px-[30px]">
                  <Button full red onClick={() => callInventorySuscribe()}>
                    Notify Me
                  </Button>
                </div>
              )}
          </div>
          <div className="h-56">
            {props.type === "physical" &&
              (props.status === "in-stock" || props.status === "low-stock") ? (
              <Link to={linkUrl}>
                <div className="p-[30px] opacity-100">
                  <div className="flex items-center justify-between mb-4">
                    <CircleAvatar
                      border
                      image={
                        props.assetsGroup
                          ? props.brand?.logo
                            ? props.brand?.logo
                            : props.brand_logo
                          : props.brand_logo
                      }
                      text={props.brand?.name || props.brand_name}
                      sm
                    />
                    {props.type && props.type === "digital" && <MetaDownload />}
                    {props.type && props.type === "physical" && (
                      <MetaDelivery />
                    )}
                  </div>
                  <h3 className="mb-2 text-med-16 group-hover:underline lg:min-h-[46px]">
                    {props.title}
                  </h3>
                  {
                    //  {typeof props.price !== "undefined" &&
                    //   props.price !== null &&
                    //   props.price !== "0.00" && (
                    //     <p className="price-label-lg !text-[13px] !leading-[23px]">
                    //       ${props.price}
                    //     </p>
                    //   )}
                  }

                  {
                    //   props.type === "physical" && stockStatus !== null && (
                    //   <div className="">
                    //     <Tags wrap>
                    //       {/* {typeof props.status === "string" && (
                    //     <>
                    //       {
                    //         {
                    //           'in-stock': <Tag plain>In Stock</Tag>,
                    //           'out-of-stock': <Tag red plain>Out of Stock</Tag>,
                    //           'low-stock': <Tag yellow plain>Low Stock</Tag>,
                    //         }[props.status]
                    //       }
                    //     </>
                    //   )}
                    //   {typeof props.status === "object" && (
                    //     <>
                    //     </>
                    //   )}
                    //   {Array.isArray(props.status) && (
                    //     <>
                    //       {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))) ? <>
                    //         {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc < 1 && <Tag red plain>Out of Stock</Tag>}
                    //         {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc > 0 && props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc < 5 && <Tag yellow plain>Low Stock</Tag>}
                    //         {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc > 4 && <Tag plain>In Stock</Tag>}
                    //       </> : <Tag red plain>Out of Stock</Tag>}
                    //     </>
                    //   )} */}
                    //       {stockStatus === "out-of-stock" && (
                    //         <Tag red plain>
                    //           Out of Stock
                    //         </Tag>
                    //       )}
                    //       {stockStatus === "low-stock" && (
                    //         <Tag yellow plain>
                    //           Low Stock
                    //         </Tag>
                    //       )}
                    //       {stockStatus === "in-stock" && <Tag plain>In Stock</Tag>}
                    //     </Tags>
                    //   </div>
                    // )
                  }
                  {props.assetsItems && (
                    <div className="flex justify-center group-hover:h-0">
                      <MetaMini
                        grey
                        title={`Includes ${props.assetsItems.length} variations`}
                      />
                    </div>
                  )}
                </div>
              </Link>
            ) : (
              <Link to={linkUrl}>
                <div className="">
                  <div className="flex items-center justify-between mb-4 p-[10px]">
                    <CircleAvatar
                      border
                      image={
                        props.assetsGroup
                          ? props.brand?.logo
                            ? props.brand?.logo
                            : props.brand_logo
                          : props.brand_logo
                      }
                      text={props.brand?.name || props.brand_name}
                      sm
                    />
                    {props.type && props.type === "digital" && <MetaDownload />}
                    {props.type && props.type === "physical" && (
                      <MetaDelivery />
                    )}
                  </div>
                  <h3
                    className={`mb-2 text-med-16 group-hover:underline lg:min-h-[46px] p-[30px] ${props.type === "physical" &&
                      props.status === "out-of-stock" &&
                      "opacity-100 group-hover:opacity-0"
                      }`}
                  >
                    {props.title}
                  </h3>
                  {
                    //  {typeof props.price !== "undefined" &&
                    //   props.price !== null &&
                    //   props.price !== "0.00" && (
                    //     <p className="price-label-lg !text-[13px] !leading-[23px]">
                    //       ${props.price}
                    //     </p>
                    //   )}
                  }

                  {
                    //   props.type === "physical" && stockStatus !== null && (
                    //   <div className="">
                    //     <Tags wrap>
                    //       {/* {typeof props.status === "string" && (
                    //     <>
                    //       {
                    //         {
                    //           'in-stock': <Tag plain>In Stock</Tag>,
                    //           'out-of-stock': <Tag red plain>Out of Stock</Tag>,
                    //           'low-stock': <Tag yellow plain>Low Stock</Tag>,
                    //         }[props.status]
                    //       }
                    //     </>
                    //   )}
                    //   {typeof props.status === "object" && (
                    //     <>
                    //     </>
                    //   )}
                    //   {Array.isArray(props.status) && (
                    //     <>
                    //       {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))) ? <>
                    //         {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc < 1 && <Tag red plain>Out of Stock</Tag>}
                    //         {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc > 0 && props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc < 5 && <Tag yellow plain>Low Stock</Tag>}
                    //         {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc > 4 && <Tag plain>In Stock</Tag>}
                    //       </> : <Tag red plain>Out of Stock</Tag>}
                    //     </>
                    //   )} */}
                    //       {stockStatus === "out-of-stock" && (
                    //         <Tag red plain>
                    //           Out of Stock
                    //         </Tag>
                    //       )}
                    //       {stockStatus === "low-stock" && (
                    //         <Tag yellow plain>
                    //           Low Stock
                    //         </Tag>
                    //       )}
                    //       {stockStatus === "in-stock" && <Tag plain>In Stock</Tag>}
                    //     </Tags>
                    //   </div>
                    // )
                  }
                  {props.assetsItems && (
                    <div className="flex justify-center group-hover:h-0">
                      <MetaMini
                        grey
                        title={`Includes ${props.assetsItems.length} variations`}
                      />
                    </div>
                  )}
                </div>
              </Link>
            )}
            {props.type === "physical" && props.status === "out-of-stock" && (
              <div className="opacity-0 group-hover:opacity-100 transition-all	-translate-y-[85px] group-hover:-translate-y-[80px] px-[30px]">
                <Button
                  full
                  red
                  hoverWhite
                  onClick={() => callInventorySuscribe()}
                >
                  Notify Me
                </Button>
              </div>
            )}

            {props?.status === "must_request" ? (
              <div className="p-[30px] opacity-0 group-hover:opacity-100 transition-all	-translate-y-[70px] group-hover:-translate-y-[180px]">
                <Button
                  red
                  // onClick={() => setModalContact(true)}
                  onClick={addToCart}
                  full
                  className="mt-10"
                >
                  Request Asset
                </Button>
              </div>
            ) : (
              <>
                {props.type === "physical" ? (
                  <>
                    {
                      //     <div className="flex items-start gap-2">
                      //   {userAddresses.length === 1 &&
                      //   assetData.status.max_qty_ordered >=
                      //     assetData.status.max_qty ? (
                      //     <>Sorry, you've reached your maximum order quantity.</>
                      //   ) : (
                      //     <div className="flex xxl:flex-col items-start gap-2 md:mt-10 mt-10">
                      //       <div className="text-center">
                      //         <div
                      //           className=" custom-number-input h-[50px] w-[80px] flex bg-white flex-row overflow-hidden"
                      //           css={css`
                      //             background: #ffffff;
                      //             border: 1px solid #ebebeb;
                      //             box-sizing: border-box;
                      //             border-radius: 8px;
                      //             &:hover {
                      //               border-color: #222;
                      //             }
                      //           `}
                      //         >
                      //           <button
                      //             data-action="decrement"
                      //             disabled={count === data?.max_qty}
                      //             onClick={decrement}
                      //             className="h-full w-20 cursor-pointer outline-none bg-white overflow-hidden"
                      //             css={css`
                      //               border-radius: 8px 0 0 8px;
                      //             `}
                      //           >
                      //             <span className="">−</span>
                      //           </button>
                      //           <input
                      //             type="text"
                      //             className="text-med-14 outline-none focus:outline-none focus:ring-0 text-center w-full bg-white border-0 overflow-hidden"
                      //             name="custom-input-number"
                      //             value={count}
                      //             onChange={e => increaseQty(e.target.value)}
                      //           />
                      //           <button
                      //             data-action="increment"
                      //             onClick={increment}
                      //             className="h-full w-20 cursor-pointer bg-white overflow-hidden"
                      //             css={css`
                      //               border-radius: 0 8px 8px 0;
                      //             `}
                      //             disabled={
                      //               parseInt(count) === parseInt(data?.max_qty)
                      //             }
                      //           >
                      //             <span className="">+</span>
                      //           </button>
                      //         </div>
                      //         <p className="max-w-[60px]">{`of ${assetData.status.available_total} in stock`}</p>
                      //       </div>
                      //       <Button
                      //         red
                      //         onClick={addToCart}
                      //         widthAuto
                      //         className={"text-[12px]"}
                      //       >
                      //         Add To Cart
                      //       </Button>
                      //     </div>
                      //   )}
                      // </div>
                    }
                  </>
                ) : (
                  <>
                    {
                      //  <div className="p-[30px] opacity-0 group-hover:opacity-100 transition-all	-translate-y-[70px] group-hover:-translate-y-[180px]">
                      //   <Button
                      //     red
                      //     full
                      //     className="mt-10"
                      //     onClick={() => orderDigitalAssetFunction(props.id)}
                      //   >
                      //     Download
                      //   </Button>
                      // </div>
                    }
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Modal
          isOpen={emptyAddressBookError}
          title="Your Address Book is Empty"
          closeModal={() => setEmptyAddressBookError(false)}
        >
          <p className="text-reg-17">
            To place an order, you must first create an address in your Address
            Book.
          </p>
          <p className="text-reg-17">
            This ensures that we ship to the correct location.
          </p>
          <br />
          <Button red onClick={() => navigateCreateAddressBookItem()} widthAuto>
            Create New Address
          </Button>
        </Modal>
      </>
    )
  }
  return (
    <>
      <div
        className={`group bg-white text-sm overflow-hidden rounded-[8px] border border-[#ebebeb] mb-4 container hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)] transition-all ${typeof displayBlock !== "undefined" && "block"
          }`}
      >
        <div className="relative">
          <div
            className={`absolute left-4 top-4 opacity-100 group-hover:opacity-0 transition-all z-[1]`}
          >
            <Tag bold upper darkGreen>
              {props.assets &&
                props.category &&
                props.category.map((v, k) => (k === 0 ? v : ", " + v))}
              {!props.assets &&
                props.categories?.length > 0 &&
                props.categories.map((v, k) => (k === 0 ? v : ", " + v))}
            </Tag>
          </div>
          {props.created_at && isLatestUpdated(props.created_at, 7) && (
            <div
              className={`absolute right-4 top-4 opacity-100 group-hover:opacity-0 transition-all z-[1]`}
            >
              <Tag bold upper red>
                New
              </Tag>
            </div>
          )}
          <div className="absolute right-4 top-4 opacity-0 group-hover:opacity-100 transition-all z-[1]">
            <AddToWishlist
              noText
              title={props.title}
              id={props.id}
              name={props.assets ? "asset" : "product"}
              isListed={
                myBookmarksID[props.assets ? "asset" : "product"]?.includes(
                  props.id
                )
                  ? true
                  : false
              }
            />
          </div>
          <Link to={linkUrl}>
            <div className="w-full h-56 ">
              {typeof coverImg === "string" &&
                (coverImg?.toLowerCase().includes(`.png`) ||
                  coverImg?.toLowerCase().includes(`.jpg`) ||
                  coverImg?.toLowerCase().includes(`.webp`) ||
                  coverImg?.toLowerCase().includes(`.jpeg`)) ? (
                <LazyImage
                  src={coverImg}
                  alt={props.title}
                  className="w-full h-full object-center object-cover bg-[#EBEBEB]"
                />
              ) : (
                <LazyImage
                  src={PlaceholderImage}
                  alt={props.title}
                  className="w-full h-full object-center object-contain bg-[#fff]"
                />
              )}
            </div>
          </Link>
          {!hiddenCta &&
            props.assets &&
            !props.assetsGroup &&
            props.type === "physical" &&
            stockStatus !== "out-of-stock" && (
              <div className="opacity-0 group-hover:opacity-100 transition-all	-translate-y-[70px] group-hover:-translate-y-[60px] px-[30px]">
                <Button full red onClick={addToCart}>
                  Add to Cart
                </Button>
              </div>
            )}
          {!hiddenCta &&
            props.assets &&
            !props.assetsGroup &&
            props.type === "physical" &&
            stockStatus === "out-of-stock" && (
              <div className="opacity-0 group-hover:opacity-100 transition-all	-translate-y-[70px] group-hover:-translate-y-[60px] px-[30px]">
                <Button full red onClick={() => callInventorySuscribe()}>
                  Notify Me
                </Button>
              </div>
            )}
        </div>
        <Link to={linkUrl}>
          <div className="p-[30px]">
            <div className="flex items-center justify-between mb-4">
              <CircleAvatar
                border
                image={
                  props.assetsGroup
                    ? props.brand?.logo
                      ? props.brand?.logo
                      : props.brand_logo
                    : props.brand_logo
                }
                text={props.brand?.name || props.brand_name}
                sm
              />
              {props.type && props.type === "digital" && <MetaDownload />}
              {props.type && props.type === "physical" && <MetaDelivery />}
            </div>
            <h3 className="mb-2 text-med-16 group-hover:underline lg:min-h-[46px]">
              {props.title}
            </h3>
            {typeof props.price !== "undefined" &&
              props.price !== null &&
              props.price !== "0.00" && (
                <p className="price-label-lg !text-[13px] !leading-[23px]">
                  ${props.price}
                </p>
              )}

            {props.type === "physical" && stockStatus !== null && (
              <div className="">
                <Tags wrap>
                  {/* {typeof props.status === "string" && (
                  <>
                    {
                      {
                        'in-stock': <Tag plain>In Stock</Tag>,
                        'out-of-stock': <Tag red plain>Out of Stock</Tag>,
                        'low-stock': <Tag yellow plain>Low Stock</Tag>,
                      }[props.status]
                    }
                  </>
                )}
                {typeof props.status === "object" && (
                  <>
                  </>
                )}
                {Array.isArray(props.status) && (
                  <>
                    {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))) ? <>
                      {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc < 1 && <Tag red plain>Out of Stock</Tag>}
                      {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc > 0 && props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc < 5 && <Tag yellow plain>Low Stock</Tag>}
                      {props.status.find(f => currentUserData.ug.includes(parseInt(f.user_group))).aloc > 4 && <Tag plain>In Stock</Tag>}
                    </> : <Tag red plain>Out of Stock</Tag>}
                  </>
                )} */}
                  {stockStatus === "out-of-stock" && (
                    <Tag red plain>
                      Out of Stock
                    </Tag>
                  )}
                  {stockStatus === "low-stock" && (
                    <Tag yellow plain>
                      Low Stock
                    </Tag>
                  )}
                  {stockStatus === "in-stock" && <Tag plain>In Stock</Tag>}
                </Tags>
              </div>
            )}
            {props.assetsItems && (
              <MetaMini
                grey
                title={`Includes ${props.assetsItems.length} assets`}
              />
            )}
          </div>
        </Link>
      </div>
      <Modal
        isOpen={emptyAddressBookError}
        title="Your Address Book is Empty"
        closeModal={() => setEmptyAddressBookError(false)}
      >
        <p className="text-reg-17">
          To place an order, you must first create an address in your Address
          Book.
        </p>
        <p className="text-reg-17">
          This ensures that we ship to the correct location.
        </p>
        <br />
        <Button red onClick={() => navigateCreateAddressBookItem()} widthAuto>
          Create New Address
        </Button>
      </Modal>
    </>
  )
}

export default ProductGrid
