import React from "react"

import BrandGrid from "../Cards/BrandGrid"

import ProductGrid from "../Cards/ProductGridCard"
import RetailerListCard from "../Cards/RetailerListCard"
export default function LikeHits(props) {
  let { dataType } = props
  if (dataType === "brand") {
    return <BrandGrid {...props} />
  } else if (dataType === "asset") {
    return <ProductGrid assets fromAssetListing {...props} />
  } else if (dataType === "product") {
    return <ProductGrid {...props} />
  } else if (dataType === "retailer") {
    return <RetailerListCard {...props} search />
  } else if (dataType === "retail-store") {
    return <RetailerListCard search isStore {...props} />
  }
  return ""
}
