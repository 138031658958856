import { navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
import { getUser, makeLogOut } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
import Button from "../components/Elements/Button"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"
import {
  ArrowLeftIcon,
  CheckIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid"
import { Link } from "gatsby"
const EmailNotVerified = ({ location }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { state, dispatch } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication, dispatchAuthentication } = useContext(
    AuthenticationContext
  )

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (authentication.currentUserData.email_verified === true) {
      navigate("/")
    } else {
      setLoading(false)
    }
  }, [authentication])

  const preLogOut = async () => {
    // let logoutoken = localStorage.getItem("logOutToken")
    let res = await makeLogOut(state.token, authentication)
    if (res.ok) {
      dispatchAuthentication({
        type: "LOGOUT",
      })
      dispatch({ type: "SET_INITIAL" })

      return true
    } else {
      toggleNotification({
        content: "Fail to Log Out",
      })
      return false
    }
  }

  const doLogOut = async () => {
    preLogOut().then(d => {
      if (d) navigate("/login", { state: { logOut: true } })
    })
  }

  if (!loading) {
    return (
      <LayoutMinimal
        title={`Please verify your email address.`}
        footer={"©2025 O2O. All rights reserved."}
        metaTitle={"Your Account"}
      >
        <div>
          <h1 className="text-strong-20 !text-[16px]  !leading-[1.2] mb-4">
            We've already sent you a verification email.
          </h1>
          <p className="text-sm mb-8 text-center">
            You still haven't verified your email address. We've already sent you a confirmation link.
          </p>
          <p className="text-sm mb-8 text-center">
            Please click the link in the email to verify your address and complete your registration.
          </p>
        </div>

        <div className="flex justify-center items-center mt-6">
          <Button red widthAuto onClick={() => doLogOut()}>
            Log Out
          </Button>
        </div>
      </LayoutMinimal>
    )
  }
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
    </div>
  )
}

export default EmailNotVerified
